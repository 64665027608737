
<template>
    <b-modal ref="ModalInfoRelatorio"
             modal-class="ModalInfoRelatorio"
             dialog-class="modalInfomacoesKanbanCP"
             content-class="contentModalInfomacoesKanbanCP"
             size="xl"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Detalhes"
             hide-footer>

        <div class="container-fluid modal-painel mx-auto h-90" style="margin-bottom:50px;">
            <div class="row">
                <div class="col" style="margin-left:15px">
                    <div class="h-100">
                        <b-row id="boxDetalhesRelatorio" class="px-2 py-2 " style="height: calc(90vh - 150px)">
                            <b-col class="h-100">
                                <div id="menu_detalhes">
                                    <div style="float: right; margin: 10px 0px;">
                                        <botao-padrao texto="Exportar" class="tabela-relatorios-botao-exportar" @click="exportarDetalhes" />
                                    </div>
                                </div>
                                <div class="app-scroll-custom pt-0 insert_scroll  h-75" style="overflow-y: scroll; border: 1px solid #e8e8e8; background: #FFF; font-size:13px;">
                                    <div v-for="(item, index) in detalhes" style="background-color: #e8e8e8; padding: 10px; margin: 10px; ">
                                        <b-row v-if="item.IsPausa">
                                            <b-col style="color:#710606">Pausa : <b>{{item.PausaNome}}</b></b-col>
                                            <b-col><b>Inicio</b>: {{item.Entrada}}  </b-col>
                                            <b-col><b>Fim</b>: {{item.Saida}} </b-col>
                                            <b-col><b>Total</b>: {{item.Calculo}} </b-col>


                                        </b-row>
                                        <b-row v-else>
                                            <b-col style="color:#710606">Entrada/Saida</b></b-col>
                                            <b-col><b>Login</b>: {{item.Entrada}}  </b-col>
                                            <b-col><b>Logout</b>: {{item.Saida}}</b-col>
                                            <b-col><b>Total</b>: {{item.Calculo}} </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>

            </div>
        </div>
    </b-modal>
</template>

<script>

    import DadosAtendimentoGuiaHistorico from '@/components/atendimento/DadosAtendimentoGuiaHistorico.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue';
    // Services

    import AtendimentoServices from '@/assets/js/services/AtendimentoServices'

    export default {
        name: 'ModalInfoRelatorio',
        components: {

            DadosAtendimentoGuiaHistorico,
            AtendimentoServices,
            InputPadrao,
            InputSelect, BotaoPadrao
        },
        props: {
            detalhes: [],


        },
        data() {
            return {

                exibeCliente: false,
                exibe: false,
                dados: null,

            }
        },
        watch: {},
        created() {},
        methods: {
            show() {
                this.exibe = true;
            },
            hide() {
                this.exibe = false
            },
            exportarDetalhes() {
                this.$emit('exportarDetalhes', this.detalhes)
            }
        },
        created() {
            this.currentAba = 1;
        }
    }
</script>
<style>
    #menuAtividadeCP {
        display: table;
        width: 100%;
    }

    .modalInfomacoesKanbanCP {
        display: flex;
        height: 90vh;
        max-width: 97% !important;
    }

    .contentModalInfomacoesKanbanCP {
        height: 100%;
        width: 98%;
        margin: 0px auto;
    }
    .footer_data {
        float: right
    }
</style>
<style scoped>
    #menu_detalhes {
        width:100%;
        display:table
    }
    #boxDetalhesRelatorio {
        background-color: #e8e8e8;
        cursor: default;
    }



    .btn_menu_conv_padrao {
        color: #FFF;
        padding: 5px;
        background-color: #88A4CA;
        cursor: pointer;
        width: 72px;
        float: right;
        margin-bottom: 6px;
        text-align: center;
    }

        .btn_menu_conv_padrao:hover {
            background-color: var(--cinza-5)
        }





    .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }


    #btn_salva_obs {
        background-color: #19b1bd;
        color: #FFF;
        width: 100%;
        cursor: pointer;
        text-align: center;
        padding: 7px;
        display: block;
        position: relative;
        bottom: 0px;
    }
</style>