import { render, staticRenderFns } from "./ModalInfoRelatorio.vue?vue&type=template&id=4c9f0036&scoped=true"
import script from "./ModalInfoRelatorio.vue?vue&type=script&lang=js"
export * from "./ModalInfoRelatorio.vue?vue&type=script&lang=js"
import style0 from "./ModalInfoRelatorio.vue?vue&type=style&index=0&id=4c9f0036&prod&lang=css"
import style1 from "./ModalInfoRelatorio.vue?vue&type=style&index=1&id=4c9f0036&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c9f0036",
  null
  
)

export default component.exports