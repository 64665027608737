<template>
    <div class="tabela-relatorios">
        <ModalInfoRelatorio ref="ModalInfoRelatorio"
                            :detalhes="detalhes"
                            @exportarDetalhes="exportarDetalhes" />
        <ModalConversaPadrao ref="modal_coversa_padrao"
                             :dadosConversa="dadosConversa"
                             :usuarios="usuarios"
                             style="display:contents" />

        <!--<b-modal ref="exportarRelatorio" hide-footer hide-header>
            <div class="container-fluid">
                <div class="row">
                    <h3>Tipo de exportação</h3>
                </div>
                <div class="row">
                    <b-form-group class="py-2">
                        <b-form-radio v-model="selecionadoExportacao" name="some-radios" value="0">Modelo compacto</b-form-radio>
                        <b-form-radio v-model="selecionadoExportacao" name="some-radios" value="1">Modelo completo</b-form-radio>
                    </b-form-group>
                </div>
                <div class="row py-3 mt-5 float-right">
                    <b-button variant="white" class="mx-3" @click="cancelarExportacaoRelatorio">Cancel</b-button>
                    <b-button variant="success" @click="exportarRelatorio()">Exportar</b-button>
                </div>
            </div>
        </b-modal>-->

        <div v-if="atendimentos || getAtendimentosIsLoading" class="tabela-relatorios-wrapper">
            <div class="container-fluid w-100 px-0">
                <div class="row align-items-baseline" style="font-size: 14px;">
                    <div class="mb-3 mx-3">
                        <button class="p-1 px-2 hover-button" style="border: 1px solid;" @click="voltarTabelaOriginal">Voltar</button>
                    </div>
                    <div class="mx-1">
                        <b>{{usuarioOrSetorSelecionado}}</b>
                        <span class="mx-3">
                            {{dataInicial.split("-")[2]}}/{{dataInicial.split("-")[1]}}/{{dataInicial.split("-")[0]}}
                            e
                            {{dataFinal.split("-")[2]}}/{{dataFinal.split("-")[1]}}/{{dataFinal.split("-")[0]}}
                        </span>
                    </div>
                </div>
                <b-col v-if="getAtendimentosIsLoading" class="text-center text-secondary font-weight-bold mt-3">
                    <b-spinner variant="secondary" small class="mr-1"></b-spinner>
                    Processando solicitação...
                </b-col>
                <b-table v-else
                         :fields="fieldsExpansao[origem]"
                         :items="atendimentos"
                         responsive
                         striped
                         hover
                         class="tabela-relatorios-btable app-scroll-custom w-0">
                    <template #cell(Informacoes)="row">
                        <div class="abrir_conversa_padrao" v-on:click='abrirConversa(row.item.Id, row.item.Protocolo, row.item.ClienteId, row)'><i class='fas fa-info-circle fa-2x conversa_funil'></i></div>
                    </template>
                    <template #cell(TA)="row">
                        {{row.item.TA.split(".").length > 2 ? `${row.item.TA.split(".")[0]} Dias, ${row.item.TA.split(".")[1]}` : row.item.TA.split(".")[0]}}
                    </template>
                    <template #cell(TE)="row">
                        {{row.item.TE.split(".").length > 2 ? `${row.item.TE.split(".")[0]} Dias, ${row.item.TE.split(".")[1]}` : row.item.TE.split(".")[0]}}
                    </template>
                </b-table>
            </div>
        </div>

        <div v-else class="tabela-relatorios-wrapper">
            <div class="container-fluid w-100 px-0">
                <div class="row">
                    <div class="d-flex col-xl-auto tabela-relatorios-filtros">
                        <input-data-tabela v-model="dataInicial" placeholder="Data inicial" class="tabela-relatorios-input-data mr-2" />
                        <input-data-tabela v-model="dataFinal" placeholder="Data final" class="tabela-relatorios-input-data mr-2" />
                        <select-tabela v-model="origem" :opcoes="opcoesOrigem" :value="origem" placeholder="Relatórios" class=" mr-2" />
                        <select-tabela v-if="exibeSetores" v-model="origemSetor" :opcoes="setores" placeholder="Setores" class=" mr-2" />
                        <select-tabela v-if="exibeUsuarios" v-model="origemUsuario" :opcoes="usuarios" placeholder="Usuarios" class=" mr-2" />
                        <div class="d-flex tabela-relatorios-acoes">
                            <botao-padrao class="tabela-relatorios-botao-filtrar mx-2 fas fa-search" @click="filtrarRelatorio(false)" />
                        </div>
                    </div>
                    <div class="d-flex col tabela-relatorios-acoes">
                        <botao-padrao texto="Estatísticas" class="tabela-relatorios-botao-estatistica mr-2" @click="abrirEstatisticas" />
                        <botao-padrao texto="Exportar relatório" class="tabela-relatorios-botao-exportar" @click="exportarRelatorioPorTipo" v-if="origem != 1" />
                    </div>
                </div>
                <div v-if="origem == 4" style="padding: 5px; background-color: #FFF;">
                    <div style="background-color:#e8e8e8">
                        &nbsp; <b>Mailings pendentes: </b> {{mailingsPendentes}}&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Mailings trabalhandos: </b>{{mailingsTrabalhados}}

                    </div>
                </div>
                <b-table v-model="visibleRows"
                         :items="dadosTabela"
                         :fields="fields[origem]"
                         :filter="filter"
                         :select-mode="selectMode"
                         ref="selectableTable"
                         selected-variant=""
                         responsive
                         striped
                         hover
                         :busy="loading"
                         class="tabela-relatorios-btable app-scroll-custom w-0"
                         @row-selected="onRowSelected">

                    <template #cell(Expandir)="row">
                        <span style="cursor: pointer;" @click="expandirInformacoes(row)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                            </svg>
                        </span>
                    </template>

                    <template #cell(Detalhes)="row">
                        <span style="display: block; margin-left: 17px; cursor: pointer;">
                            <b-icon icon="exclamation-diamond" scale="2" @click="abrirInfoRelatorio(row)"></b-icon>
                        </span>
                    </template>
                    <template #cell(Informacoes)="row">
                        <i class='fas fa-info-circle fa-2x conversa_funil abrir_conversa_padrao' @click="abrirConversaDetalhes(row)"></i>
                    </template>
                </b-table>
                <div class="tabela-relatorios-metricas d-flex justify-content-sm-between pl-0">
                    <div class="d-flex w-0">
                        <paginacao-seta ref="paginacao_seta"
                                        :offset="quantidadesdePaginas"
                                        :selecionado="pagina"
                                        @eventoPaginacao="eventoPaginacao" />
                    </div>

                    <div class="d-flex w-0">
                        <span> {{ RegistrosEncontrados }} registros(s) </span>
                    </div>
                    <div class="d-flex w-0">
                        <span v-if="exibeMedia">Tempo Médio: {{ tempoMedio }}</span>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="modalEstatisticas" title="Estatisticas" ok-only :size="origem == 0 || origem == 7 ? 'xl' : 'lg'">
            <div v-if="origem == 0" class="d-flex justify-content-around">
                <div class="my-2">
                    <h5 class="text-center mb-3">TMA</h5>
                    <GraficoColuna :labelsDataHorizontal="dadosTabela.map(list => { return list.UsuarioNome })"
                                   :labelsDataVertical="dadosTabela.map(list => { return list.TMATotalPorSegundos })"
                                   :isResponsive="false"
                                   :labelData="'Segundos total TMA'" />
                </div>
                <div class="my-2">
                    <h5 class="text-center mb-3">TME</h5>
                    <GraficoColuna :labelsDataHorizontal="dadosTabela.map(list => { return list.UsuarioNome })"
                                   :labelsDataVertical="dadosTabela.map(list => { return list.TMETotalPorSegundos })"
                                   :isResponsive="false"
                                   :labelData="'Segundos total TME'" />
                </div>
            </div>

            <div v-if="origem == 1" class="d-flex justify-content-center">
                <GraficoRosca :label="getAtendimentosPendentesGrafico.map((list) => list[0])"
                              :dados="getAtendimentosPendentesGrafico.map((list) => list[1].length)"
                              :responsive="false" />
            </div>

            <div v-if="origem == 2" class="d-flex justify-content-center">
                <div class="text-center">
                    <h5>Entradas</h5>
                    <GraficoRosca :label="dadosTabela.map(list => { return list.UsuarioNome })"
                                  :dados="dadosTabela.map(list => { return list.Entrada })"
                                  :responsive="false" />
                </div>

                <div class="text-center">
                    <h5>Finalizadas</h5>
                    <GraficoRosca :label="dadosTabela.map(list => { return list.UsuarioNome })"
                                  :dados="dadosTabela.map(list => { return list.FinalizadoSaida })"
                                  :responsive="false" />
                </div>
            </div>

            <div v-if="origem == 3" class="d-flex justify-content-center">
                <div class="text-center">
                    <h5>Encerradas pelo Usuário</h5>
                    <GraficoRosca :label="AtendimentoEncerradoLabels.map(list => {return list.EncerradasPeloUsuario})"
                                  :dados="dadosTabela.map(list => {return list.EncerradoUsuario})"
                                  :responsive="false" />
                </div>

                <div class="text-center">
                    <h5>Encerradas Automaticamente</h5>
                    <GraficoRosca :label="AtendimentoEncerradoLabels.map(list => {return list.EncerradasAutomaticamente})"
                                  :dados="dadosTabela.map(list => {return list.EncerradoAutomatico})"
                                  :responsive="false" />
                </div>
            </div>

            <div v-if="origem == 4" class="d-flex justify-content-center">
                <GraficoColuna :labelsDataHorizontal="getMailingsGrafico.UsuarioNome"
                               :labelsDataVertical="getMailingsGrafico.QuantidadeMailings"
                               :isResponsive="false"
                               :labelData="'Quantidade de Mailings por Atendente'" />
            </div>

            <div v-if="origem == 5" class="d-flex justify-content-center">
                <GraficoColuna :labelsDataHorizontal="dadosTabela.map(list => {return list.SetorNome})"
                               :labelsDataVertical="dadosTabela.map(list => {return list.QntAtendimentos})"
                               :isResponsive="false"
                               :labelData="'Por Setor'" />
            </div>

            <div v-if="origem == 7" style="text-align: -webkit-center;">
                <div class="row my-3">
                    <div class="col">
                        <h5 class="text-center mb-3">Total Soma Diaria</h5>
                        <GraficoColuna :labelsDataHorizontal="dadosTabela.map(list => {return list.Dia})"
                                       :labelsDataVertical="dadosTabela.map(list => {return list.TotalSomaDiariaEmSegundos})"
                                       :isResponsive="false"
                                       :labelData="'Total Soma Diaria em Segundos'" />
                    </div>

                    <div class="col">
                        <h5 class="text-center mb-3">Total Soma Pausa</h5>
                        <GraficoColuna :labelsDataHorizontal="dadosTabela.map(list => {return list.Dia})"
                                       :labelsDataVertical="dadosTabela.map(list => {return list.TotalSomaPausaEmSegundos})"
                                       :isResponsive="false"
                                       :labelData="'Total Soma Pausa em Segundos'" />
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { utils, read, writeFileXLSX } from "xlsx";
    import axios from 'axios'
    import InputPesquisa from '@/components/inputs/InputPesquisa.vue';
    import InputDataTabela from '@/components/inputs/InputDataTabela.vue';
    import SelectTabela from '@/components/inputs/SelectTabela.vue';
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue';
    import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta';
    import { mapActions, mapGetters } from 'vuex';
    import ModalInfoRelatorio from '@/components/consultas/ModalInfoRelatorio.vue'
    import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao.vue'
    import GraficoColuna from '../graficos/GraficoColuna.vue'
    import GraficoRosca from '../graficos/GraficoRosca.vue'

    export default {
        name: 'TabelaRelatorios',
        components: {
            GraficoColuna,
            GraficoRosca,
            InputPesquisa,
            BotaoPadrao,
            InputDataTabela,
            SelectTabela,
            PaginacaoSeta,
            ModalInfoRelatorio,
            ModalConversaPadrao
        },

        props: {
            items: {
                type: Array,
            },
            quantidadesItems: {
                type: Number,
                default: 1
            },
            loading: {
                type: Boolean,
                default: false
            },
            filtrosIniciais: {
                type: Object,
                default: null
            },
            enumOrigem: {
                type: Array,
                default: null
            },
            enumStatus: {
                type: Array,
                default: null
            },
            enumUsuarios: {
                type: Array,
                default: null
            },
            selectTabulacao: {
                type: Array,
                default: null
            },
            atendimentoTabulacoes: {
                type: Array,
                default: () => []
            }
        },

        data: function () {
            return {
                AtendimentoEncerradoLabels: [{ EncerradasPeloUsuario: 'Encerradas pelo Usuário', EncerradasAutomaticamente: 'Encerradas Automaticamente' }],
                filter: null,
                selectMode: 'single',
                dataInicial: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                dataFinal: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                origem: '',
                origemSetor: '',
                origemUsuario: '',
                atendente: '',
                visibleRows: [],
                dadosTabela: [],
                pagina: 1,
                quantidadesdePaginas: 1,
                opcoesOrigem: [{ valor: 0, texto: "TMA/TME" },
                { valor: 1, texto: "Atendimentos pendentes" },
                { valor: 2, texto: "Entrada/saída" },
                { valor: 3, texto: "Atendimentos encerrados" },
               // { valor: 4, texto: "Mailing" },
                { valor: 5, texto: "Atendimento por Setor" },
                { valor: 7, texto: "Horas logadas por usuario" },
                ],
                RegistrosEncontrados: 0,
                exibeMedia: false,
                exibeSetores: true,
                exibeUsuarios: false,
                detalhes: [],
                dadosConversa: {},
                mailingsPendentes: 0,
                mailingsTrabalhados: 0,
                fields: [
                    //00
                    [
                        { key: 'Expandir', label: 'Expandir' },
                        { key: 'UsuarioNome', label: 'Usuario', sortable: false, sortDirection: 'asc' },
                        { key: 'TMA', label: 'TMA', sortable: false, sortDirection: 'asc' },
                        { key: 'TME', label: 'TME', sortable: false, sortDirection: 'asc' },
                    ],
                    //01
                    [
                        { key: 'Informacoes', label: 'Informações', sortable: false, sortDirection: 'asc' },
                        { key: 'Chamado', label: 'Protocolo', sortable: false, sortDirection: 'asc' },
                        { key: 'Cliente', label: 'Cliente', sortable: false, sortDirection: 'asc' },
                        { key: 'Contato', label: 'Contato', sortable: false, sortDirection: 'asc' },
                        { key: 'Canal', label: 'Canal', sortable: false, sortDirection: 'asc' },
                        { key: 'Atendente', label: 'Atendente', sortable: false, sortDirection: 'asc' },
                        { key: 'DataEntrada', label: 'Data de Entrada', sortable: false, sortDirection: 'asc' },
                        { key: 'TempoEspera', label: 'Tempo de Espera', sortable: false, sortDirection: 'asc' }

                    ],
                    //02
                    [
                        { key: 'Expandir', label: 'Expandir', sortable: false, sortDirection: 'asc' },
                        { key: 'UsuarioNome', label: 'Usuario', sortable: false, sortDirection: 'asc' },
                        { key: 'Entrada', label: 'Entradas', sortable: false, sortDirection: 'asc' },
                        { key: 'FinalizadoSaida', label: 'Finalizadas', sortable: false, sortDirection: 'asc' },
                    ],
                    //03
                    [
                        { key: 'Expandir', label: 'Expandir', sortable: false, sortDirection: 'asc' },
                        { key: 'TipoEncerramento', label: 'Tipo de Encerramento', sortable: false, sortDirection: 'asc' },
                        { key: 'Quantidade', label: 'Quantidade', sortable: false, sortDirection: 'asc' },
                    ],
                    //04
                    [
                        { key: 'Informacoes', label: 'Informações', sortable: false, sortDirection: 'asc' },
                        { key: 'Protocolo', label: 'Atendimento', sortable: false, sortDirection: 'asc' },
                        { key: 'Atendente', label: 'Atendente', sortable: false, sortDirection: 'asc' },
                        { key: 'Cliente', label: 'Cliente', sortable: false, sortDirection: 'asc' },

                    ],
                    //05
                    [
                        { key: 'Expandir', label: 'Expandir', sortable: false, sortDirection: 'asc' },
                        { key: 'SetorNome', label: 'Setor', sortable: false, sortDirection: 'asc' },
                        { key: 'QntAtendimentos', label: 'Quatidade atendimentos', sortable: false, sortDirection: 'asc' },
                    ],
                    //06
                    [],
                    //07
                    [
                        { key: 'Detalhes', label: 'Detalhes', sortable: false, sortDirection: 'asc' },
                        { key: 'Dia', label: 'Dia', sortable: false, sortDirection: 'asc' },
                        { key: 'SomaDiaria', label: 'Horas logada', sortable: false, sortDirection: 'asc' },
                        { key: 'SomaPausa', label: 'Pausas', sortable: false, sortDirection: 'asc' },
                        { key: 'EmOperacao', label: 'Em operação', sortable: false, sortDirection: 'asc' },
                    ]

                ],
                qtdAtendimentoPendentes: 0,
                mailingsGrafico: [],
                fieldsExpansao: [
                    //00
                    [
                        { key: 'Informacoes', label: 'Informações' },
                        { key: 'Protocolo', label: 'Protocolo', sortable: true, sortDirection: 'asc' },
                        { key: 'NomeCliente', label: 'Cliente', sortable: false, sortDirection: 'asc' },
                        { key: 'NomeSetor', label: 'Setor', sortable: false, sortDirection: 'asc' },
                        { key: 'NomeCanal', label: 'Canal', sortable: false, sortDirection: 'asc' },
                        { key: 'DataEntrada', label: 'Data de Entrada', sortable: true, sortDirection: 'asc' },
                        { key: 'DataSaida', label: 'Data de Saída', sortable: false, sortDirection: 'asc' },
                        { key: 'TA', label: 'TA', sortable: false, sortDirection: 'asc' },
                        { key: 'TE', label: 'TE', sortable: false, sortDirection: 'asc' },
                    ],
                    //01
                    [],
                    //02
                    [
                        { key: 'Informacoes', label: 'Informações' },
                        { key: 'Protocolo', label: 'Protocolo', sortable: true, sortDirection: 'asc' },
                        { key: 'NomeCliente', label: 'Cliente', sortable: false, sortDirection: 'asc' },
                        { key: 'NomeCanal', label: 'Canal', sortable: false, sortDirection: 'asc' },
                        { key: 'DataEntrada', label: 'Data de Entrada', sortable: true, sortDirection: 'asc' },
                        { key: 'DataSaida', label: 'Data de Saída', sortable: false, sortDirection: 'asc' },
                    ],
                    //03
                    [
                        { key: 'Informacoes', label: 'Informações' },
                        { key: 'Protocolo', label: 'Protocolo', sortable: true, sortDirection: 'asc' },
                        { key: 'NomeUsuario', label: 'Usuario', sortable: true, sortDirection: 'asc' },
                        { key: 'NomeCliente', label: 'Cliente', sortable: false, sortDirection: 'asc' },
                        { key: 'NomeCanal', label: 'Canal', sortable: false, sortDirection: 'asc' },
                        { key: 'DataEntrada', label: 'Data de Entrada', sortable: true, sortDirection: 'asc' },
                        { key: 'DataSaida', label: 'Data de Saída', sortable: false, sortDirection: 'asc' },
                    ],
                    //04
                    [],
                    //05
                    [
                        { key: 'Informacoes', label: 'Informações' },
                        { key: 'Protocolo', label: 'Protocolo', sortable: true, sortDirection: 'asc' },
                        { key: 'NomeCliente', label: 'Cliente', sortable: false, sortDirection: 'asc' },
                        { key: 'NomeCanal', label: 'Canal', sortable: false, sortDirection: 'asc' },
                        { key: 'NomeUsuario', label: 'Atendente', sortable: true, sortDirection: 'asc' },
                        { key: 'DataEntrada', label: 'Data de Entrada', sortable: true, sortDirection: 'asc' },
                        { key: 'DataSaida', label: 'Data de Saída', sortable: false, sortDirection: 'asc' },
                    ]
                ],
                atendimentos: null,
                usuarioOrSetorSelecionado: '',
                getAtendimentosIsLoading: false,
                isAtendimentoAutomatico: false,
                selecionadoExportacao: null,
                isPaginado: false,
            }
        },
        watch: {
            origem() {
                this.dadosTabela = [];
                this.RegistrosEncontrados = 0;
                this.quantidadesdePaginas = 1;
                if (this.origem == 7) {
                    this.exibeSetores = false;
                    this.exibeUsuarios = true;
                } else {
                    this.exibeSetores = true;
                    this.exibeUsuarios = false;
                }
            },

        },
        computed: {
            ...mapGetters({
                getSetor: 'setores/listaSetores',
                listaUsuarios: 'usuarios/listaUsuarios',

            }),
            usuarios: function () {
                if (this.listaUsuarios) {
                    let listagemUsuarios = this.listaUsuarios
                        .map(obj => ({
                            Id: obj.Id,
                            Nome: obj.Nome,
                            valor: obj.Id,
                            texto: obj.Nome
                        }));
                    return listagemUsuarios;
                }
                return [];
            },

            setores: function () {
                if (this.getSetor) {
                    let listagemSetores = this.getSetor.map(obj => ({
                        Id: obj.Id,
                        Nome: obj.Nome,
                        valor: obj.Id,
                        texto: obj.Nome,
                    }));
                    return listagemSetores;
                }
                return [];
            },

            getAtendimentosPendentesGrafico() {
                return Object.entries(this.dadosTabela, ({ Atendente }) => { return Atendente })
            },

            getMailingsGrafico() {
                return {
                    UsuarioNome: this.mailingsGrafico.map(list => { return list.UsuarioNome ? list.UsuarioNome : 'Sem Nome' }),
                    QuantidadeMailings: this.mailingsGrafico.map(list => { return list.QuantidadeMailings })
                }
            }
        },

        methods: {
            filtrarRelatorio(isPagined) {
                
                $("#caixa_carregamento").show();
               
                if (this.origem == 7) {
                    if (this.origemUsuario.length == 0) {
                        this.exibirToast("danger", "Você deve selecionar o Usuario!")
                        $("#caixa_carregamento").hide();
                        return;
                    }
                }
                if ((this.origem.length == 0 || this.origemSetor.length == 0) && this.origem != 7 && !isPagined) {
                    this.exibirToast("danger", "Você deve selecionar o RELATORIO e o SETOR!")
                }
                if (!isPagined) {
                    this.$refs.paginacao_seta.select = 1
                    this.pagina = 0
                  //  $("#caixa_carregamento").hide();
                }
                if (this.origem == 7 && this.origemUsuario == '') return;
                if (this.origem) {
                    this.dadosTabela = [];
                    var data = JSON.stringify({
                        "RelarotioTipo": this.origem,
                        "Inicio": this.dataInicial,
                        "Fim": this.dataFinal,
                        "SetorId":  this.origemSetor,
                        "UsuarioId": this.origemUsuario,
                        "Pagina": this.pagina,
                        "QntItens": 10
                    });
                   
                    var config = {
                        method: 'post',
                        url: '/api/relatorios/RelatoriosTipo',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                };

                axios(config)
                    .then((response) => {
                        const dadosList = response.data.List;
                        this.RegistrosEncontrados = response.data.TotalResults;
                        this.quantidadesdePaginas = this.RegistrosEncontrados <= 10 ? 1 : Math.ceil(this.RegistrosEncontrados / 10)
                        if (this.origem == 4) {
                            const dadosMailing = dadosList[0].RelatorioMailings.MailingsAtendimentos;
                            this.mailingsPendentes = dadosList[0].RelatorioMailings.MailingsPendentes;
                            this.mailingsTrabalhados = dadosList[0].RelatorioMailings.MailingsTrabalhados;
                            this.mailingsGrafico = dadosList[0].RelatorioMailings.MailingsGrafico;
                            this.dadosTabela = dadosMailing;
                          
                            return;
                        }
                        if (this.origem == 1) {
                            const dadosPendente = dadosList[0].RelatoriosAtendimentosPendente;
                            this.dadosTabela = dadosPendente;
                            if (dadosPendente.length == 0) {
                               
                                this.exibirToast("info", "Não houve resultado para sua consulta!")
                            }
                            
                            $("#caixa_carregamento").hide();
                            return;
                        }
                        if (this.origem == 7) {
                            const horasLogadas = dadosList[0].RelatoriosHorasLogada[0].ConjuntoRegistro

                           
                            $("#caixa_carregamento").hide();
                            this.dadosTabela = horasLogadas.map(f => {
                                f.SomaDiaria = f.SomaDiaria.split('.').length > 2 ? f.SomaDiaria.split('.')[0] + ' dia(s) ' + f.SomaDiaria.split('.')[1] : f.SomaDiaria.split('.')[0];
                                f.SomaPausa = f.SomaPausa.split('.').length > 2 ? f.SomaPausa.split('.')[0] + ' dia(s) ' + f.SomaPausa.split('.')[1] : f.SomaPausa.split('.')[0];
                                f.EmOperacao = f.EmOperacao.split('.').length > 2 ? f.EmOperacao.split('.')[0] + ' dia(s) ' + f.EmOperacao.split('.')[1] : f.EmOperacao.split('.')[0];
                               
                                return f;
                            });
                          
                            return;
                        }
                        const dadosTMATME = dadosList[0].ListaUsuarioTmaTme.map(f => {
                            f.TMA = f.TMA.split('.').length > 2 ? f.TMA.split('.')[0] + ' dia(s) ' + f.TMA.split('.')[1] : f.TMA.split('.')[0];
                            f.TME = f.TME.split('.').length > 2 ? f.TME.split('.')[0] + ' dia(s) ' + f.TME.split('.')[1] : f.TME.split('.')[0];
                           
                            return f;
                        });
                        const dadosEntradaSaida = dadosList[0].RelatoriosEntradaSaida;
                        const dadosEcerrado = dadosList[0].RelatoriosAtendimentosEncerrados;
                        const dadosSetores = dadosList[0].ListaAtendimentosSetores;
                        this.dadosTabela = this.origem == 0 ?
                            dadosTMATME : this.origem == 2 ?
                                dadosEntradaSaida : this.origem == 3 ?
                                    dadosEcerrado : this.origem == 5 ?
                                        dadosSetores : this.origem == 7 ? dadosHorasLogadas : []; 3

                       

                        if ((this.origem == 2 && dadosEntradaSaida.length == 0) || (this.origem == 5 && dadosSetores.length == 0) || (this.origem == 0 && dadosTMATME.length == 0)) {
                            this.exibirToast("info", "Não houve resultado para sua consulta!")
                        }
                        $("#caixa_carregamento").hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
              

            },
            eventoPaginacao(valor) {
                this.pagina = valor;
                this.filtrarRelatorio(true);
            },
            cancelarExportacaoRelatorio() {
                this.$refs.exportarRelatorio.hide()
            },
            exportarRelatorioPorTipo() {
                var data = JSON.stringify({
                    "RelarotioTipo": this.origem,
                    "Inicio": this.dataInicial,
                    "Fim": this.dataFinal,
                    "SetorId": this.origemSetor,
                    "UsuarioId": this.origemUsuario,
                    "Pagina": 1,
                    "QntItens": 10
                });
                var config = {
                    method: 'post',
                    url: '/api/relatorios/ExportarRelatorioPorTipo',
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios(config)
                    .then((res) => {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(new Blob([res.data]))
                        link.download = `relatorio_${Date.now()}.xlsx`
                        link.click();
                        link.remove();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            onRowSelected(items) {
                if (!items || items.length == 0 || !items[0]) {
                    return
                }
            },
            abrirInfoRelatorio: function (valor) {
                this.$refs.ModalInfoRelatorio.show();
                const dadosEnvio = valor.item.Registro.map(f => {
                    f.Calculo = f.Calculo.split('.').length > 2 ? f.Calculo.split('.')[0] + ' dia(s) ' + f.Calculo.split('.')[1] : f.Calculo.split('.')[0];
                    return f;
                });
                this.detalhes = dadosEnvio;
            },
            abrirConversaDetalhes: function (linha) {
                const valor = { Id: linha.item.AtendimentoId, Protocolo: linha.item.Atendimento, ClienteId: linha.item.ClienteId }
                this.dadosConversa = valor;
                this.$refs.modal_coversa_padrao.show();
            },
            exportarDetalhes(detalhes) {
                var data = JSON.stringify({
                    "Detalhes": detalhes
                });
                var config = {
                    method: 'post',
                    url: '/api/relatorios/ExportarDetalhesHorasLogadas',
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config)
                    .then((res) => {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(new Blob([res.data]))
                        link.download = `relatorio_Detalhes_horas_logadas_${Date.now()}.xlsx`
                        link.click();
                        link.remove();

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            async abrirEstatisticas() {

                this.$refs['modalEstatisticas'].show()
            },
            getAtendimentos(usuarioId, setorId) {
                this.getAtendimentosIsLoading = true
                return axios.get(`/api/relatorios/${!usuarioId ? '' : usuarioId}`, {
                    params: {
                        "DataInicial": this.dataInicial,
                        "DataFinal": this.dataFinal,
                        "IsAtendimentoAutomatico": this.isAtendimentoAutomatico,
                        "SetorId": !setorId ? null : setorId
                    }
                }).then(res => {
                    this.atendimentos = res.data
                    if (this.$route.params.usuarioId) this.usuarioOrSetorSelecionado = this.atendimentos.find(value => value.UsuarioId == usuarioId).NomeUsuario;
                    else if (this.$route.params.setorId) this.usuarioOrSetorSelecionado = this.atendimentos.find(value => value.SetorId == setorId).NomeSetor;
                    this.getAtendimentosIsLoading = false
                }).catch(err => {
                    console.log('getAtendimentos: ', err)
                })
            },
            expandirInformacoes(row) {
                if (this.origem == 3) {
                    this.isAtendimentoAutomatico = row.index == 0 ? false : true;
                }

                if ((row.item.UsuarioId || row.item.SetorId) && !this.$route.params.usuarioId) {
                    this.$router.replace({
                        path: `relatorios/${row.item.UsuarioId ? 'usuario/' + row.item.UsuarioId : 'setor/' + row.item.SetorId}`,
                        query: {
                            "DataInicial": this.dataInicial,
                            "DataFinal": this.dataFinal,
                            "Origem": this.origem
                        }
                    });

                }
                else if (!row.item.UsuarioId) {
                    this.$router.replace({
                        path: `/relatorios`,
                        query: {
                            "DataInicial": this.dataInicial,
                            "DataFinal": this.dataFinal,
                            "Origem": this.origem,
                            "IsAtendimentoAutomatico": this.isAtendimentoAutomatico
                        }
                    });
                    this.usuarioOrSetorSelecionado = this.isAtendimentoAutomatico ? "Atendimentos Encerrados Automaticamente" : "Atendimentos Encerrados pelo Usuário"
                }
                return this.getAtendimentos(row.item.UsuarioId, row.item.SetorId);
            },
            voltarTabelaOriginal() {
                this.atendimentos = null
                this.usuarioOrSetorSelecionado = ''
                if (this.$route.params.usuarioId || this.$route.query) this.$router.replace({ path: `/relatorios` });
                //if (this.dadosTabela.length == 0) this.filtrarRelatorio()
            },
            abrirConversa: function (id, protocolo, clienteId) {
                const valor = { Id: id, Protocolo: protocolo, ClienteId: clienteId }
                this.dadosConversa = valor;
                this.$refs.modal_coversa_padrao.show()
            },
            exibirToast(variant, mensagem) {
                this.$bvToast.toast(mensagem, {
                    toaster: 'b-toaster-top-center',
                    title: `Error`,
                    variant: variant,
                    solid: true
                })
            }
        },
        created() {
            if (JSON.stringify(this.$route.query) != "{}") {
                this.dataInicial = this.$route.query.DataInicial
                this.dataFinall = this.$route.query.DataFinal
                this.origem = this.$route.query.Origem
                this.isAtendimentoAutomatico = this.$route.query.IsAtendimentoAutomatico

                if (this.$route.params.usuarioId || this.$route.params.setorId) {
                    this.getAtendimentos(this.$route.params.usuarioId, this.$route.params.setorId);
                } else {
                    this.getAtendimentos(null, null);
                    this.usuarioOrSetorSelecionado = this.$route.query.IsAtendimentoAutomatico == 'true' ? "Atendimentos Encerrados Automaticamente" : "Atendimentos Encerrados pelo Usuário"
                }
            }


        }

    }
</script>

<style scope>
    .tabela-relatorios {
        padding: 12px;
        background-color: var(--cinza-3);
        width: 100%;
        font-size: 12px;
    }

    .tabela-relatorios-wrapper {
        height: 100%;
    }

    .tabela-relatorios-filtros {
        height: 45px;
        padding-bottom: 10px
    }

    .tabela-relatorios-acoes {
        justify-content: flex-end;
        height: 45px;
        padding-bottom: 10px
    }

    .tabela-relatorios-input-data {
        min-width: 120px;
        max-width: 120px;
        border: none !important;
    }

    .tabela-relatorios-input-pesquisar {
        width: 300px !important;
    }

    .tabela-relatorios-botao-filtrar {
        width: 50px;
    }

    .tabela-relatorios-botao-estatistica {
        width: 140px;
    }

    .tabela-relatorios-botao-exportar {
        width: 180px;
    }

    .tabela-relatorios-btable {
        background-color: #fff;
        height: calc(100vh - 335px);
        margin-bottom: 0 !important;
        font-size: 12px;
    }

    .b-table-row-selected {
        background-color: rgba(214,230,234,0.87);
    }

    .tabela-relatorios-metricas {
        color: var(--cinza-5);
        font-weight: bold;
        height: 40px;
        padding: 10px 5px 0px 5px;
        font-size: 12px;
        align-items: baseline;
    }

    .abrir_conversa_padrao {
        display: table;
        cursor: pointer;
    }

    .input-pesquisa {
        width: 100%;
        height: 35px;
    }

    .abrir_conversa_padrao {
        display: table;
        cursor: pointer;
    }

    .hover-button {
        background-color: var(--cinza-3);
    }

        .hover-button:hover {
            background-color: var(--cinza-4);
        }

        .hover-button:active {
            background-color: var(--cinza-2);
        }

    .input-pesquisa-input {
        height: 35px;
        border-radius: inherit !important;
        border: none;
        box-shadow: none;
        font-weight: bold;
        color: var(--cinza-5);
        font-size: 13px !important;
        height: calc(1.5em + 0.75rem + 5px) !important;
        border: 1px transparent solid;
    }
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        .input-pesquisa-input::placeholder {
            color: var(--cinza-5);
            opacity: 1; /* Firefox */
        }
        /* Internet Explorer 10-11 */
        .input-pesquisa-input:-ms-input-placeholder {
            color: var(--cinza-5);
        }
        /* Microsoft Edge */
        .input-pesquisa-input::-ms-input-placeholder {
            color: var(--cinza-5);
        }

        .input-pesquisa-input:hover {
            border-color: black;
        }

        .input-pesquisa-input:active {
            border: 1px var(--cor-primaria-cliente) solid;
            border-radius: inherit;
        }
</style>